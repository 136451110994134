import React, { useContext, useEffect } from 'react';
import { SiGmail, SiMicrosoftoutlook } from 'react-icons/si'; // Import Gmail and Outlook icons
import { BannerContext } from '../../context/bannerContext';
import GmailLogo from '../../assets/Gmail Logo.png'
import OutlookLogo from '../../assets/outlook logo.png'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const CheckEmail = () => {
    const { setBannerState } = useContext(BannerContext);
    const navigate = useNavigate();
    const email = Cookies.get('email') ? Cookies.get('email') : null;
    console.log("email", email);


    useEffect(() => {
        setBannerState({
            title: 'Getting you ready to exit your business',
            information: 'When exiting the business there are many different things that naturally run through your mind. Speaking to your trusted advisors such as your accountant, solicitor or broker can be hugely beneficial.',
        });
    }, [setBannerState]);
    return (
        <div className="h-full pt-[5%] Barlow">
            <h2 className="text-2xl font-bold text-[#04394F]">Check your email</h2>
            <p className='text-[#696868]'>We’ve sent an email to <strong className='text-black opacity-100'>{email ? email : "xyz@gmail.com"}</strong> to confirm your signup.</p>
            <div className="flex gap-20 mt-[10%]">
                <div className='flex gap-2 w-40'>
                    <img src={GmailLogo} alt='Gmail Logo' width="auto" height="auto" />
                    <a href="https://mail.google.com" target="_blank" rel="noopener noreferrer" className='mt-3 hover:font-bold transition-all ease-linear cursor-pointer'>Open Gmail</a>
                </div>
                <div className='flex gap-2 w-40'>
                    <img src={OutlookLogo} alt='Outlook Logo' width="auto" height="auto" />
                    <a href="https://outlook.live.com" target="_blank" rel="noopener noreferrer" className='mt-3 hover:font-bold transition-all ease-linear cursor-pointer'>Open Outlook</a>
                </div>
            </div>
            <div className='mt-[10%]'>
                <p className="text-left text-[#0A0A0C] font-semibold text-2xl">
                    Didn’t get an email? Check your spam folder.
                </p>
                <a onClick={()=>(navigate(-1))} className="text-blue-600 hover:font-bold transition-all ease-linear cursor-pointer">Re-enter your email and try again</a>
            </div>
        </div>
    );
};

export default CheckEmail;
