import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Container, InputGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../utils/networkconfig";
import { FaEye, FaEyeSlash, FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { BannerContext } from "../../context/bannerContext";

const AdminResetPassword = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setloading] = useState(false);
    const [validation, setValidation] = useState({
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isMinLength: false,
    });

    const { setBannerState } = useContext(BannerContext);
    const navigate = useNavigate();

    useEffect(() => {
        setBannerState({
            title: 'Welcome back to Pinnacle Business Finance.',
            information: 'Pinnacle Business Finance is a commercial finance brokerage based in Bristol. We are an unrivalled team of commercial finance experts.',
        });
    }, [setBannerState]);


    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);

        // Validate password conditions
        setValidation({
            hasLowercase: /[a-z]/.test(value),
            hasUppercase: /[A-Z]/.test(value),
            hasNumber: /\d/.test(value),
            hasSpecialChar: /[!@#$%^&*]/.test(value),
            isMinLength: value.length >= 8,
        });
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setNewConfirmPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if password validation is met
        if (!validation.hasLowercase || !validation.hasUppercase || !validation.hasNumber || !validation.hasSpecialChar || !validation.isMinLength) {
            toast.error("Password does not meet the required criteria.");
            return;
        }

        if (newPassword !== newConfirmPassword) {
            toast.error("Password does not match");
            return;
        }

        setloading(true);

        try {
            const response = await axios.post(
                `${url}/api/password-reset/confirm/`,
                { token, new_password: newPassword }
            );
            toast.success(response.data.message || "Password reset successfully.");
            setTimeout(() => {
                navigate('/admin')
            }, 1000);

        } catch (error) {
            toast.error(error.response?.data.message || "Failed to reset password.");
        }
        finally {
            setloading(false);
        }
    };

    return (
        <Container className="!p-[10%] space-y-4">
            <h2 className="text-3xl font-bold mb-4 text-center text-[#04394F]">Reset Your Password</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                            required
                        />
                        <InputGroup.Text
                            onClick={() => setShowPassword(!showPassword)}
                            className="cursor-pointer"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </InputGroup.Text>
                    </InputGroup>

                    <div className="mt-2 text-xs">
                        <div className="flex gap-[9%]">
                            <div className="flex items-center space-x-2">
                                <FaCheckCircle
                                    className={`mb-3 ${validation.hasLowercase ? 'text-green-600' : 'text-gray-500'}`}
                                />
                                <p className={validation.hasLowercase ? 'text-green-600' : 'text-gray-500'}>One lowercase character</p>
                            </div>

                            <div className="flex items-center space-x-2">
                                <FaCheckCircle
                                    className={`mb-3 ${validation.hasNumber ? 'text-green-600' : 'text-gray-500'}`}
                                />
                                <p className={validation.hasNumber ? 'text-green-600' : 'text-gray-500'}>One number</p>
                            </div>

                            <div className="flex items-center space-x-2">
                                <FaCheckCircle
                                    className={`mb-3 ${validation.hasUppercase ? 'text-green-600' : 'text-gray-500'}`}
                                />
                                <p className={validation.hasUppercase ? 'text-green-600' : 'text-gray-500'}>One uppercase character</p>
                            </div>
                        </div>

                        <div className="flex gap-[12.5%]">
                            <div className="flex items-center space-x-2">
                                <FaCheckCircle
                                    className={`mb-3 ${validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'}`}
                                />
                                <p className={validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'}>One special character</p>
                            </div>
                            <div className="flex items-center space-x-2">
                                <FaCheckCircle
                                    className={`mb-3 ${validation.isMinLength ? 'text-green-600' : 'text-gray-500'}`}
                                />
                                <p className={validation.isMinLength ? 'text-green-600' : 'text-gray-500'}>8 characters minimum</p>
                            </div>
                        </div>
                    </div>

                    <Form.Label>Re-enter New Password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Re-enter new password"
                            value={newConfirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        <InputGroup.Text
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="cursor-pointer"
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>

                <Button className="!w-full mt-4" variant="primary" type="submit">
                    {loading ? <Spinner size="sm" color="white"></Spinner> : "Reset Password"}
                </Button>
            </Form>
            <ToastContainer />
        </Container>
    );
};

export default AdminResetPassword;
