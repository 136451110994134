import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner, Row, Col } from 'react-bootstrap';

const EditModal = ({ show, onHide, title, formData, onSave, formLoading }) => {
    const [formState, setFormState] = useState(formData || {});

    useEffect(() => {
        setFormState(formData || {});
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setFormState((prev) => ({ ...prev, resident_type: value }));
    };

    const handleSubmit = () => {
        onSave(formState);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {/* Full Name and Business Name */}
                        {formState.full_name !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="full_name">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="full_name"
                                            value={formState.full_name || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="business_name">
                                        <Form.Label>Business Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="business_name"
                                            value={formState.business_name || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Phone Number */}
                        {formState.phone_number !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="phone_number">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone_number"
                                            value={formState.phone_number || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="phone_number">
                                        <Form.Label>Home Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="home_address"
                                            value={formState.home_address || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Resident Type</Form.Label>
                                        <div>
                                            <div className='flex gap-[30%]'>
                                                <Form.Check
                                                    type="radio"
                                                    label="Home Owner"
                                                    value="home_owner"
                                                    checked={formState.resident_type === 'home_owner'}
                                                    onChange={handleRadioChange}
                                                    />
                                                <Form.Check
                                                    type="radio"
                                                    label="Tenant"
                                                    value="tenant"
                                                    checked={formState.resident_type === 'tenant'}
                                                    onChange={handleRadioChange}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Other"
                                                    value="other"
                                                    checked={formState.resident_type === 'other'}
                                                    onChange={handleRadioChange}
                                                />
                                            </div>
                                            {formState.resident_type === 'other' && (
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Please specify"
                                                    value={formState?.if_other}
                                                    onChange={handleChange}
                                                    className="mt-2"
                                                />
                                            )}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Business Type and Industry Sector */}
                        {formState.business_type !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="formBusinessType">
                                        <Form.Label>Business Type<span className="text-red-500">*</span></Form.Label>
                                        <Form.Select name="business_type" value={formState.business_type || ''} onChange={handleChange}>
                                            <option value="">Select your option</option>
                                            <option value="sole_proprietorship">Sole Proprietorship</option>
                                            <option value="partnership">Partnership</option>
                                            <option value="corporation">Corporation</option>
                                            <option value="llc">Limited Liability Company (LLC)</option>
                                            <option value="nonprofit">Nonprofit Organization</option>
                                            <option value="startup">Startup</option>
                                            <option value="franchise">Franchise</option>
                                            <option value="cooperative">Cooperative</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="formIndustrySector">
                                        <Form.Label>Industry Sector<span className="text-red-500">*</span></Form.Label>
                                        <Form.Select name="industry_sector" value={formState.industry_sector || ''} onChange={handleChange}>
                                            <option value="">Select your option</option>
                                            <option value="it">Information Technology (IT)</option>
                                            <option value="finance">Finance & Banking</option>
                                            <option value="healthcare">Healthcare & Pharmaceuticals</option>
                                            <option value="manufacturing">Manufacturing</option>
                                            <option value="retail">Retail & E-commerce</option>
                                            <option value="construction">Construction & Real Estate</option>
                                            <option value="education">Education & Training</option>
                                            <option value="agriculture">Agriculture</option>
                                            <option value="transportation">Transportation & Logistics</option>
                                            <option value="energy">Energy & Utilities</option>
                                            <option value="hospitality">Hospitality & Tourism</option>
                                            <option value="media">Media & Entertainment</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Business Start Date and Number of Employees */}
                        {formState.business_start_date !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="business_start_date">
                                        <Form.Label>Business Start Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="business_start_date"
                                            value={formState.business_start_date || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="formNumberOfEmployees">
                                        <Form.Label>Number of Employees<span className="text-red-500">*</span></Form.Label>
                                        <Form.Select name="number_of_employees" value={formState.number_of_employees || ''} onChange={handleChange}>
                                            <option value="">Select</option>
                                            <option value="5 - 10">5 - 10</option>
                                            <option value="10 - 20">10 - 20</option>
                                            <option value="20 - 50">20 - 50</option>
                                            <option value="Above 50">Above 50</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Finance Type and Loan Amount */}
                        {formState.finance_type !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="finance_type">
                                        <Form.Label>Finance Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="finance_type"
                                            value={formState.finance_type || ''}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="amount_required">
                                        <Form.Label>Amount Required</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="amount_required"
                                            value={formState.amount_required || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Purpose of Loan and Repayment Period */}
                        {formState.purpose !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="purpose">
                                        <Form.Label>Purpose of Application</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="purpose"
                                            value={formState.purpose || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="formRepaymentPeriod">
                                        <Form.Label>Repayment Period<span className="text-red-500">*</span></Form.Label>
                                        <Form.Select
                                            value={formState.repayment_period || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="1 - 2 Years">1 - 2 Years</option>
                                            <option value="2 - 3 Years">2 - 3 Years</option>
                                            <option value="3 - 5 Years">3 - 5 Years</option>
                                            <option value="More than 5 Years">More than 5 Years</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </>
                        )}

                        {/* Annual Revenue, Net Profit, and Outstanding Debt */}
                        {formState.annual_revenue !== undefined && (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId="annual_revenue">
                                        <Form.Label>Annual Revenue</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="annual_revenue"
                                            value={formState.annual_revenue || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="net_profit">
                                        <Form.Label>Net Profit</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="net_profit"
                                            value={formState.net_profit || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="outstanding_debt">
                                        <Form.Label>Outstanding Debt</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="outstanding_debt"
                                            value={formState.outstanding_debt || ''}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={formLoading}>
                    {formLoading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
