import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Card, Dropdown, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import '../../styles/ApplicationDetails.css';
import { FaArrowLeft, FaRegEdit } from 'react-icons/fa';
import UserDocumentsList from '../../custom_components/UserDocumentList';
import { DocumentUploadContext } from '../../context/DocumentUploadContext';
import EditModal from '../../custom_components/Edit_Modal';
import { url } from '../../utils/networkconfig';



const UserApplicationDetails = () => {

    const { isDocumentUploaded } = useContext(DocumentUploadContext);  // Access the context


    const { application_id } = useParams();  // Get application_id from URL params
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    // const [modalData, setModalData] = useState({ name: '', email: '', application_id: '', document_id: null });
    const [showModal, setShowModal] = useState({ basic: false, business: false, funding: false, financial: false });
    const [modalData, setModalData] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();


    // Fetch application data from API
    useEffect(() => {
        axios.get(`${url}/api/applications/${application_id}/`)
            .then(response => {
                setUserData(response.data);
                console.log("user Data:", response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    setError("Application Not Found");
                } else {
                    toast.error('Failed to fetch application data');
                }
                setLoading(false);
            });

    }, [application_id, isDocumentUploaded]);

    const statusColors = {
        completed: 'bg-green-500',
        in_progress: 'bg-yellow-200',
        under_review: 'bg-gray-300',
        default: 'bg-gray-100'
    };

    if (loading) return <p>Loading...</p>;

    // If there's an error, show the alert
    if (error) {
        return (
            <Alert variant="danger" className="text-center mt-5">
                {error}
            </Alert>
        );
    }

    const applicationStatus = {
        status: userData.application_status.status,
        last_updated: new Date(userData.application_status.last_updated)
    };


    const handleOpenModal = (section, data) => {
        setModalData(data);
        setShowModal(prev => ({ ...prev, [section]: true }));
    };

    const handleCloseModal = (section) => {
        setShowModal(prev => ({ ...prev, [section]: false }));
    };

    const handleSaveData = async (section, apiEndpoint, updatedData) => {
        setFormLoading(true);
        if (updatedData.resident_type !== 'other') {
            updatedData.if_other = null;
        }
        try {
            const response = await axios.put(`${url}/api/applications/${application_id}/${apiEndpoint}/`, updatedData);

            // Update only the section that was edited
            setUserData(prevData => ({
                ...prevData,
                [section]: updatedData,  // Update the specific section
            }));

            setShowModal({ basic: false, business: false, funding: false, financial: false });
            toast.success("Data updated successfully!");
        } catch (error) {
            toast.error("Failed to update data.");
        } finally {
            setFormLoading(false);
        }
    };

    const handleNewApplication = async () => {
        try {
            const response = await axios.post(`${url}/api/applications/`, {
                user_id: userData.user_id, // Replace this with the actual user ID
            });

            const applicationId = response.data.id; // Get the ID of the created application

            // Navigate to the new application's page
            navigate(`/dashboard/NewApplication/${applicationId}`);
        } catch (error) {
            console.error("Error creating application:", error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <div className="container">
            {/* ToastContainer */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Row className='flex-1 justify-between'>
                <Col md={8}>
                    <h3 className="text-2xl text-[#04394F] font-bold mb-4 text-left">My Applications</h3>
                </Col>

                <Col className='text-end'>
                    <button onClick={() => (navigate('/dashboard/NewApplication'))} className='bg-[#B38D2E] text-white rounded-md p-2 px-4 font-semibold'>New Application</button>
                </Col>
            </Row>
            <div className="flex items-center justify-between p-3 border-b border-gray-200 mb-3">
                {/* User Info */}
                <div className="flex items-center">
                    <div className="text-left">
                        <h2 className="text-xl font-semibold text-[#04394F]">Application ID # {application_id}</h2>
                        <p className="text-sm text-black Barlow">Busniess Loan</p>
                    </div>
                </div>

                {/* Last Activity Info */}
                <div className="text-sm text-gray-500">
                    <div className="flex items-center">
                        <i className="mr-2">🕒</i>
                        <span className='text-[#04394F] text-sm'>Date Submitted</span>
                    </div>
                    <div className='Barlow text-xs'>08/29/2024  |  23:00</div>
                </div>

                {/* Message Button
                <div>
                    <button className="bg-[#B38D2E] px-2 pt-2 text-white rounded flex gap-2" onClick={() => handleOpenMessageModal(
                        userData.basic_contact_information.full_name,
                        userData.basic_contact_information.email,
                        application_id
                    )}>
                        <div>
                            <img src={ChatLogo} alt='Logo'></img>
                        </div>
                        <div>
                            <p className='Barlow text-[9px]'>Message</p>
                            <p className='Barlow text-[12px] font-semibold -mt-5'>{userData.basic_contact_information.full_name}</p>
                        </div>
                    </button>
                </div> */}

                {/* Status and Dropdown */}
                <div className="flex items-center">
                    <div className={`bg-gray-200 text-gray-800 py-2 px-4 rounded mr-2 font-semibold ${statusColors[applicationStatus.status.toLowerCase()]}`}>
                        {applicationStatus.status.charAt(0).toUpperCase() + applicationStatus.status.slice(1).replace('_', ' ')}
                    </div>
                </div>
            </div>
            <div className="space-y-4 mb-4">
                {/* Basic Contact Information */}
                <Card className='custom-card-body'>
                    <Card.Body >
                        <h4 className='flex'>Basic Contact Information <span className='ml-2 cursor-pointer' onClick={() => handleOpenModal('basic', userData.basic_contact_information)}><FaRegEdit /></span></h4>
                        <Row>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Full Name</strong>
                                <p>{userData.basic_contact_information.full_name}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Business Name</strong>
                                <p>{userData.basic_contact_information.business_name}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Phone Number</strong>
                                <p>{userData.basic_contact_information.phone_number}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Home Address</strong>
                                <p>{userData.basic_contact_information.home_address}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Resident Type</strong>
                                <p>
                                    {userData?.basic_contact_information?.resident_type
                                        ? userData.basic_contact_information.resident_type
                                            .replace(/_/g, ' ')
                                            .split(' ')
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(' ')
                                        : 'N/A'
                                    }
                                </p>
                            </Col>
                            {userData.basic_contact_information.resident_type === 'other' &&
                                <Col md={4} sm={6} xs={12}>
                                    <strong>Other Specified</strong>
                                    <p>{userData.basic_contact_information.if_other}</p>
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>

                {/* Business Details */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4 className='flex'>Business Details <span className='ml-2 cursor-pointer' onClick={() => handleOpenModal('business', userData.business_details)}><FaRegEdit /></span></h4>
                        <Row>
                            <Col>
                                <strong>Business Type</strong>
                                <p>{userData.business_details.business_type.charAt(0).toUpperCase() + userData.business_details.business_type.slice(1).replace('_', ' ')}</p>
                            </Col>
                            <Col>
                                <strong>Industry Sector</strong>
                                <p>{userData.business_details.industry_sector}</p>
                            </Col>
                            <Col>
                                <strong>Business Start Date</strong>
                                <p>{userData.business_details.business_start_date}</p>
                            </Col>
                            <Col>
                                <strong>Number of Employees</strong>
                                <p>{userData.business_details.number_of_employees}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* Funding Requirements */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4 className='flex'>Funding Requirements <span className='ml-2 cursor-pointer' onClick={() => handleOpenModal('funding', userData.funding_requirements)}><FaRegEdit /></span></h4>
                        <Row>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Finance Type</strong>
                                <p>{userData.funding_requirements.finance_type.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Loan Amount Required</strong>
                                <p>{userData.funding_requirements.amount_required}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Purpose of Loan</strong>
                                <p>{userData.funding_requirements.purpose}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Repayment Period</strong>
                                <p>{userData.funding_requirements.repayment_period}</p>
                            </Col>
                            <Col>
                                <strong>Preferred Funding Timeline</strong>
                                <p>{userData.funding_requirements.preferred_funding_timeline}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* Financial Information */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4 className='flex'>Financial Information <span className='ml-2 cursor-pointer' onClick={() => handleOpenModal('financial', userData.financial_information)}><FaRegEdit /></span></h4>
                        <Row>
                            <Col>
                                <strong>Annual Revenue</strong>
                                <p>{userData.financial_information.annual_revenue}</p>
                            </Col>
                            <Col>
                                <strong>Net Profit</strong>
                                <p>{userData.financial_information.net_profit}</p>
                            </Col>
                            <Col>
                                <strong>Outstanding Debt</strong>
                                <p>{userData.financial_information.outstanding_debt}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div>
                    {/* Documents List */}
                    <UserDocumentsList documents={userData.document_uploads}
                        name={userData.basic_contact_information.full_name}
                        email={userData.basic_contact_information.email}
                        application_id={application_id} />
                </div>
            </div>
            {/* Basic Contact Information Modal */}
            <EditModal
                show={showModal.basic}
                onHide={() => handleCloseModal('basic')}
                title="Edit Basic Contact Information"
                formData={modalData}
                onSave={(data) => handleSaveData('basic_contact_information', 'basic-contact', data)}  // Pass 'basic_contact_information'
                formLoading={formLoading}
            />

            {/* Business Details Modal */}
            <EditModal
                show={showModal.business}
                onHide={() => handleCloseModal('business')}
                title="Edit Business Details"
                formData={modalData}
                onSave={(data) => handleSaveData('business_details', 'business-details', data)}  // Pass 'business_details'
                formLoading={formLoading}
            />

            {/* Funding Requirements Modal */}
            <EditModal
                show={showModal.funding}
                onHide={() => handleCloseModal('funding')}
                title="Edit Funding Requirements"
                formData={modalData}
                onSave={(data) => handleSaveData('funding_requirements', 'funding-requirements', data)}  // Pass 'funding_requirements'
                formLoading={formLoading}
            />

            {/* Financial Information Modal */}
            <EditModal
                show={showModal.financial}
                onHide={() => handleCloseModal('financial')}
                title="Edit Financial Information"
                formData={modalData}
                onSave={(data) => handleSaveData('financial_information', 'financial-information', data)}  // Pass 'financial_information'
                formLoading={formLoading}
            />
        </div>
    );
};

export default UserApplicationDetails;
