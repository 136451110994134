import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Card, Dropdown, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocumentsList from '../AdminComponents/DocumentList';
import ApplicationStatus from '../AdminComponents/ApplicationStatus';
import ReferralCommission from '../AdminComponents/ReferralComission';
import '../../styles/ApplicationDetails.css';
import ChatLogo from "../../assets/chatIcon.png";
import SendMessageModal from '../AdminComponents/AdminSendMessage';
import { url } from '../../utils/networkconfig';

const WarningModal = ({ show, handleClose, onConfirm }) => (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title className='!text-[#04394F] !font-bold'>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure? This action can't be undone.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="danger" onClick={onConfirm}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
);

const ApplicationDetails = () => {
    const { application_id } = useParams();  // Get application_id from URL params
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalData, setModalData] = useState({ name: '', email: '', application_id: '', document_id: null });
    const navigate = useNavigate();
    const [docStatus, setDocStatus] = useState({});  // Moved state here
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [actionType, setActionType] = useState(null); // 'approve' or 'reject'


    // Fetch application data from API
    useEffect(() => {
        axios.get(`${url}/api/admin_applications/${application_id}/`)
            .then(response => {
                setUserData(response.data);
                console.log("user Data:", response.data);

                // Initialize the docStatus state based on the document uploads
                const initialDocStatus = response.data.document_uploads.reduce(
                    (acc, doc) => ({ ...acc, [doc.id]: doc.status }),
                    {}
                );
                setDocStatus(initialDocStatus);

                setLoading(false);


            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    setError("Application Not Found");
                } else {
                    toast.error('Failed to fetch application data');
                }
                setLoading(false);
            });
    }, [application_id]);

    // Function to update document status in userData
    const updateDocumentStatusInUserData = (document_id, newStatus) => {
        if (userData && userData.document_uploads) {
            const updatedDocuments = userData.document_uploads.map(doc =>
                doc.id === document_id ? { ...doc, status: newStatus } : doc
            );
            setUserData(prevData => ({
                ...prevData,
                document_uploads: updatedDocuments
            }));
            setDocStatus((prevState) => ({
                ...prevState,
                [document_id]: newStatus, // Update the local state with the new status
            }));
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'completed':
                return 'bg-[#00B67A] text-white'; // #00B67A
            case 'in_progress':
                return 'bg-[#E8DDC0] text-black'; // #E8DDC0
            case 'under_review':
                return 'bg-[#E3E3E3] text-black'; // #E3E3E3
            case 'submitted':
                return 'bg-[#00B67A]'; // #E3E3E3
            case 'approved':
                return 'bg-[#00B67A]'; // #E3E3E3
            case 'rejected':
                return 'bg-[#C5221F]'; // #E3E3E3
            default:
                return 'bg-[#E3E3E3] text-black'; // Fallback color
        }
    };

    const statusColors = {
        completed: 'bg-[#E3E3E3]',
        in_progress: 'bg-[#E3E3E3]',
        under_review: 'bg-[#E3E3E3]',
        default: 'bg-[#E3E3E3]'
    };



    // Handle dropdown change for status update 
    const handleStatusChange = (newStatus) => {
        // Make PUT request to update application status
        axios.put(`${url}/api/application/${application_id}/status/`, { status: newStatus })
            .then(response => {
                setUserData(prevData => ({
                    ...prevData,
                    application_status: {
                        ...prevData.application_status,
                        status: newStatus
                    },
                    // Update the relevant entry in application_history
                    application_history: prevData.application_history.map(entry =>
                        entry.application_id === parseInt(application_id)
                            ? { ...entry, status: newStatus }
                            : entry
                    )
                }));
                toast.success('Application status updated successfully');
            })
            .catch(error => {
                toast.error('Failed to update application status');
            });
    };

    if (loading) return <p>Loading...</p>;

    // If there's an error, show the alert
    if (error) {
        return (
            <Alert variant="danger" className="text-center mt-5">
                {error}
            </Alert>
        );
    }

    const applicationStatus = {
        status: userData.application_status.status,
        last_updated: new Date(userData.application_status.last_updated)
    };

    // Function to open the modal for messaging or asking for documents
    const handleOpenMessageModal = (name, email, application_id, document_id = null) => {
        setModalData({ name, email, application_id, document_id });
        setShowMessageModal(true);

    };

    const handleCloseMessageModal = () => {
        setShowMessageModal(false);
    };

    const handleApproveApplication = async () => {
        try {
            const response = await fetch(`${url}/api/application/${application_id}/status/`,
                {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        status: 'approved',
                    }),
                }
            )
            if (response.ok) {
                const data = await response.json();
                console.log('Application approved:', data);
                navigate('/admin/dashboard/myapplications')
            } else {
                console.error('Failed to approve application');
            }
        }

        catch (error) {
            console.error('Error:', error);
        }
    }

    const handleRejectApplication = async () => {
        try {
            const response = await fetch(`${url}/api/application/${application_id}/status/`,
                {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        status: 'rejected',
                    }),
                }
            )
            if (response.ok) {
                const data = await response.json();
                console.log('Application approved:', data);
                navigate('/admin/dashboard/myapplications')
            } else {
                console.error('Failed to approve application');
            }
        }

        catch (error) {
            console.error('Error:', error);
        }
    }

    const handleApproveClick = () => {
        setActionType('approve');
        setShowWarningModal(true);
    };

    const handleRejectClick = () => {
        setActionType('reject');
        setShowWarningModal(true);
    };

    const handleConfirmAction = () => {
        setShowWarningModal(false);

        if (actionType === 'approve') {
            handleApproveApplication();
        } else if (actionType === 'reject') {
            handleRejectApplication();
        }
    };

    return (
        <div className="container">
            <Row className='flex-1 justify-between'>
                <Col md={8}>
                    <h3 className="text-2xl text-[#04394F] font-bold mb-4 text-left">Application Details</h3>
                </Col>

                <Col className='text-end'>
                    <button onClick={handleApproveClick} disabled={applicationStatus.status === 'approved' || applicationStatus.status === 'rejected'} className={`bg-[#00B67A] text-white rounded-md p-2 px-4 font-semibold ${applicationStatus.status === 'approved' || applicationStatus.status === 'rejected' ? 'opacity-50 cursor-not-allowed' : ''}`}>Approve Application</button>
                </Col>
            </Row>
            <div className="flex items-center justify-between p-3 border-b border-gray-200 mb-3">
                {/* User Info */}
                <div className="flex items-center">
                    <div className="text-left">
                        <h2 className="text-xl font-semibold text-[#04394F]">{userData.basic_contact_information.full_name}</h2>
                        <p className="text-sm text-black Barlow">{userData.user_email}</p>
                    </div>
                </div>

                {/* Last Activity Info */}
                <div className="text-sm text-gray-500">
                    <div className="flex items-center">
                        <i className="mr-2">🕒</i>
                        <span className='text-[#04394F] text-sm font-semibold'>Last Activity</span>
                    </div>
                    <div className='Barlow text-xs'>{new Date(applicationStatus.last_updated).toLocaleString()}</div>
                </div>

                {/* Message Button */}
                <div>
                    <button className="bg-[#B38D2E] px-2 pt-2 text-white rounded flex gap-2" onClick={() => handleOpenMessageModal(
                        userData.basic_contact_information.full_name,
                        userData.basic_contact_information.email,
                        application_id
                    )}>
                        <div>
                            <img src={ChatLogo} alt='Logo'></img>
                        </div>
                        <div>
                            <p className='Barlow text-[9px]'>Message</p>
                            <p className='Barlow text-[12px] font-semibold -mt-5'>{userData.basic_contact_information.full_name}</p>
                        </div>
                    </button>
                </div>

                {/* Status and Dropdown */}
                <div className="flex items-center">
                    <div className={`text-[#04394F] font-semibold py-2 px-4 rounded mr-2 ${statusColors[applicationStatus.status.toLowerCase()]}`}>
                        {applicationStatus.status.charAt(0).toUpperCase() + applicationStatus.status.slice(1).replace('_', ' ')}
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle className={`!text-[#04394F] bg-transparent !underline ${applicationStatus.status === 'approved' || applicationStatus.status === 'rejected' ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={applicationStatus.status === 'approved' || applicationStatus.status === 'rejected'} >
                            Change status
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleStatusChange('under_review')}>Under review</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleStatusChange('in_progress')}>In Progress</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleStatusChange('completed')}>Completed</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="space-y-4 mb-4">
                <Row>
                    <Col className="flex-1">
                        <ApplicationStatus status={applicationStatus} colors={statusColors} history={userData.application_history} />
                    </Col>
                    <Col className="flex-1">
                        <ReferralCommission commission={{
                            total_referrals: userData.referral_count
                            , total_amount_paid: 0, under_process_referrals: userData.referral_count
                        }} />
                    </Col>
                </Row>

                {/* Basic Contact Information */}
                <Card className='custom-card-body'>
                    <Card.Body >
                        <h4>Basic Contact Information</h4>
                        <Row>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Full Name</strong>
                                <p>{userData.basic_contact_information.full_name}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Business Name</strong>
                                <p>{userData.basic_contact_information.business_name}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Phone Number</strong>
                                <p>{userData.basic_contact_information.phone_number}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Home Address</strong>
                                <p>{userData.basic_contact_information.home_address}</p>
                            </Col>
                            <Col md={4} sm={6} xs={12}>
                                <strong>Resident Type</strong>
                                <p>{userData.basic_contact_information.resident_type}</p>
                            </Col>
                            {userData.basic_contact_information.resident_type === 'other' &&
                                <Col md={4} sm={6} xs={12}>
                                    <strong>Other Specified</strong>
                                    <p>{userData.basic_contact_information.if_other}</p>
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>

                {/* Business Details */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4>Business Details</h4>
                        <Row>
                            <Col>
                                <strong>Business Type</strong>
                                <p>{userData.business_details.business_type.charAt(0).toUpperCase() + userData.business_details.business_type.slice(1).replace('_', ' ')}</p>
                            </Col>
                            <Col>
                                <strong>Industry Sector</strong>
                                <p>{userData.business_details.industry_sector}</p>
                            </Col>
                            <Col>
                                <strong>Business Start Date</strong>
                                <p>{userData.business_details.business_start_date}</p>
                            </Col>
                            <Col>
                                <strong>Number of Employees</strong>
                                <p>{userData.business_details.number_of_employees}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* Funding Requirements */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4>Funding Requirements</h4>
                        <Row>
                            <Col>
                                <strong>Loan Amount Required</strong>
                                <p>{userData.funding_requirements.amount_required}</p>
                            </Col>
                            <Col>
                                <strong>Purpose of Loan</strong>
                                <p>{userData.funding_requirements.purpose}</p>
                            </Col>
                            <Col>
                                <strong>Repayment Period</strong>
                                <p>{userData.funding_requirements.repayment_period}</p>
                            </Col>
                            <Col>
                                <strong>Preferred Funding Timeline</strong>
                                <p>{userData.funding_requirements.preferred_funding_timeline}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* Financial Information */}
                <Card className='custom-card-body'>
                    <Card.Body>
                        <h4>Financial Information</h4>
                        <Row>
                            <Col>
                                <strong>Annual Revenue</strong>
                                <p>{userData.financial_information.annual_revenue}</p>
                            </Col>
                            <Col>
                                <strong>Net Profit</strong>
                                <p>{userData.financial_information.net_profit}</p>
                            </Col>
                            <Col>
                                <strong>Outstanding Debt</strong>
                                <p>{userData.financial_information.outstanding_debt}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div>
                    {/* Documents List */}
                    <DocumentsList documents={userData.document_uploads} handleOpenMessageModal={handleOpenMessageModal}
                        name={userData.basic_contact_information.full_name}
                        email={userData.basic_contact_information.email}
                        application_id={application_id}
                        docStatus={docStatus}  // Pass docStatus
                        setDocStatus={setDocStatus}  // Pass setDocStatus
                    />
                </div>

                <div className='flex flex-1 justify-end gap-4'>
                    {userData.document_uploads.some(doc => doc.status === 'under_review') && (
                        <>
                            <button onClick={handleRejectClick} disabled={applicationStatus.status === 'approved' || applicationStatus.status === 'rejected'} className={`bg-[#C5221F] text-white rounded-md p-2 px-4 font-semibold ${applicationStatus.status === 'approved' || applicationStatus.status === 'rejected' ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                Reject Application
                            </button>
                            <button onClick={handleApproveClick} disabled={applicationStatus.status === 'approved' || applicationStatus.status === 'rejected'} className={`bg-[#00B67A] text-white rounded-md p-2 px-4 font-semibold ${applicationStatus.status === 'approved' || applicationStatus.status === 'rejected' ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                Approve Application
                            </button>
                        </>
                    )}
                </div>
            </div>
            {/* Send Message Modal */}
            <SendMessageModal
                show={showMessageModal}
                handleClose={handleCloseMessageModal}
                name={modalData.name}
                email={modalData.email}
                application_id={modalData.application_id}
                document_id={modalData.document_id}
                updateDocumentStatus={updateDocumentStatusInUserData}  // Pass update function as prop
                setDocStatus={setDocStatus}
            />

            <WarningModal
                show={showWarningModal}
                handleClose={() => setShowWarningModal(false)}
                onConfirm={handleConfirmAction}
            />
        </div>
    );
};

export default ApplicationDetails;
