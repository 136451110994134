import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../utils/networkconfig";
import { ToastContainer, toast } from "react-toastify";
import { BannerContext } from "../../context/bannerContext";
import Cookies from 'js-cookie';


const UserForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const { setBannerState } = useContext(BannerContext);
    const navigate = useNavigate();

    useEffect(() => {
        setBannerState({
            title: "Your Business Finance Always Comes First",
            information:
                "Pinnacle Business Finance is a commercial finance brokerage based in Bristol. We are an unrivalled team of commercial finance experts.",
        });
    }, [setBannerState]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        const newErrors = {};

        // Email validation
        if (!email) {
            newErrors.email = "Email is required.";
        } else if (!validateEmail(email)) {
            newErrors.email = "Please enter a valid email address.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true); // Start loading
        try {
            const response = await axios.post(`${url}/api/password-reset/`, { email });
            toast.success("Verification link sent to your email!");
            Cookies.set('email', email, { expires: 7 });
            navigate("/checkEmail");
        } catch (error) {
            toast.error(
                error.response?.data?.message || "Something went wrong! Please try again."
            );
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <Container className="!p-[10%] space-y-4">
            <h2 className="text-3xl font-bold mb-4 text-center text-[#04394F]">
                Forgot Password?
            </h2>
            <p className="text-center font-normal text-base Barlow">
                Enter your registered email below, and we will send you a link for verification.
            </p>
            <Form onSubmit={handleForgotPassword}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({ ...errors, email: null }); // Reset email error
                        }}
                        isInvalid={!!errors.email}
                        required
                    />
                    {errors.email && (
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                <Button className="!w-full mt-4" variant="primary" type="submit">
                    {loading ? <Spinner size="sm" animation="border" /> : "Reset Password"}
                </Button>
            </Form>

            <ToastContainer />
        </Container>
    );
};

export default UserForgotPassword;
