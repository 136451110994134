import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { ProgressContext } from '../../context/ProgressContext';
import { BannerContext } from '../../context/bannerContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const Step1 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();
    const location = useLocation(); // Hook to access the location and query params



    // State variables for form inputs
    const [fullName, setFullName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const [isEditMode, setIsEditMode] = useState(false);
    const [residentType, setResidentType] = useState('home_owner');
    const [otherResidentType, setOtherResidentType] = useState('');

    // Get user ID from cookies
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const application_id = user ? user.application_id : null;

    // Fetch the existing contact information when the component mounts
    useEffect(() => {
        setProgress({
            text: "Step 1 out of 6",
            percentage: 0
        });
        if (application_id) {
            fetchContactInformation();
        } else {
            navigate('/login');
        }
    }, [application_id, navigate]);

    // Set the banner state
    useEffect(() => {
        setBannerState({
            title: 'Contact Information',
            information: 'Provide your contact details so we can stay connected throughout the onboarding process. This information will be used to keep you updated on your application status.',
        });
    }, [setBannerState]);

    // Function to fetch existing contact information
    const fetchContactInformation = async () => {
        setLoading(true); // Show spinner while fetching data
        try {
            const response = await axios.get(`${url}/api/applications/${application_id}/basic-contact/`);
            if (response.data) {
                setFullName(response.data.full_name);
                setBusinessName(response.data.business_name);
                setPhoneNumber(response.data.phone_number);
                setHomeAddress(response.data.home_address)
                setResidentType(response.data.resident_type);
                response.data.resident_type === 'other' && setOtherResidentType(response.data.if_other);
                setIsEditMode(true); // Set to edit mode if data exists
            }
        } catch (error) {
            console.error('Error fetching contact information:', error);
            setIsEditMode(false); // No existing record, we'll create a new one
        } finally {
            setLoading(false); // Stop loading spinner after fetching
        }
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Show spinner during submission

        // Validate the form
        if (!validateForm()) {
            setLoading(false);
            return;
        }

        const formData = {
            application_id: application_id,
            full_name: fullName,
            business_name: businessName,
            phone_number: phoneNumber,
            home_address: homeAddress,
            resident_type: residentType,
            if_other: residentType === 'other' ? otherResidentType : null
        };

        try {
            if (isEditMode) {
                // If we are in edit mode, perform a PUT request
                await axios.put(`${url}/api/applications/${application_id}/basic-contact/`, formData);
                setToastMessage('Contact information updated successfully.');
                const queryParams = new URLSearchParams(location.search);
            } else {
                // If not in edit mode, perform a POST request
                await axios.post(`${url}/api/applications/${application_id}/basic-contact/`, formData);
                setToastMessage('Contact information saved successfully.');
            }
            setToastVariant('success');
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.get('edit') === 'true') {
                navigate('/steps/step6'); // Navigate back to the previous page
            } else {
                navigate('/steps/step2'); // Otherwise, proceed to the next step
            }
        } catch (error) {
            setToastMessage('An error occurred while saving your information.');
            setToastVariant('danger');
            console.error('Error submitting form:', error);
        } finally {
            setShowToast(true); // Show toast notification
            setLoading(false); // Stop spinner after submission
        }
    };

    // Validate the form
    const validateForm = () => {
        if (!fullName.trim() || !businessName.trim() || !phoneNumber.trim() || !homeAddress.trim()) {
            setToastMessage('All fields are required.');
            setToastVariant('warning');
            setShowToast(true);
            return false;
        }
        if (!/^\d{11}$/.test(phoneNumber)) { // Adjust this regex pattern as needed
            setToastMessage('Please enter a valid 11-digit phone number.');
            setToastVariant('warning');
            setShowToast(true);
            return false;
        }
        if (residentType === 'other' && !otherResidentType.trim()) {
            setToastMessage('Please specify the resident type if "Other" is selected.');
            setToastVariant('warning');
            setShowToast(true);
            return false;
        }
        return true;
    };

    return (
        <div>
            <Form className="pt-[8%] px-[12%] -ml-3 w-[103.5%]" onSubmit={handleSubmit}>
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F]">Basic Contact Information</h2>
                <div className='max-h-[40vh] overflow-y-auto custom-scrollbar pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        <Form.Group controlId="formFullName">
                            <Form.Label>Full Name<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your full name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formBusinessName">
                            <Form.Label className="text-gray-700">Business Name<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your business name"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label className="text-gray-700">Phone Number<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*$/.test(inputValue)) {
                                        setPhoneNumber(inputValue);
                                    }
                                }}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label className="text-gray-700">Home Address<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter your Home Address"
                                value={homeAddress}
                                onChange={(e) => setHomeAddress(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Resident Type<span className="text-red-500">*</span></Form.Label>
                            <div>
                                <div className='flex gap-[5%]'>
                                    <Form.Check
                                        type="radio"
                                        label="Home Owner"
                                        value="home_owner"
                                        checked={residentType === 'home_owner'}
                                        onChange={(e) => setResidentType(e.target.value)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Tenant"
                                        value="tenant"
                                        checked={residentType === 'tenant'}
                                        onChange={(e) => setResidentType(e.target.value)}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Other"
                                        value="other"
                                        checked={residentType === 'other'}
                                        onChange={(e) => setResidentType(e.target.value)}
                                    />
                                </div>
                                {residentType === 'other' && (
                                    <Form.Control
                                        type="text"
                                        placeholder="Please specify"
                                        value={otherResidentType}
                                        onChange={(e) => setOtherResidentType(e.target.value)}
                                        className="mt-2"
                                    />
                                )}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div
                    className="absolute bottom-[18%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>
                <div className='mt-5'>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md hover:bg-yellow-500"
                        type="submit"
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>

            {/* Toast Notification */}
            <ToastContainer className="p-3" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={toastVariant}>
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Step1;
